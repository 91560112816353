/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/
$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    if (isSingleJournal()) {
        setupJournalPage();
    } else if (isHome()) {
        setupArchiveJournal();
    }
});

/*------------------------------------*\
Archvie Journal
\*------------------------------------*/
function setupArchiveJournal() {
    setupArchiveJournalMenu();
    setupArchiveJournalSwipers();
}


function setupArchiveJournalMenu() {
    $(".s-journals-nav-single-top").on('click', function () {
        console.log('click');
        var $current_menu = $(this);
        if ($current_menu.hasClass('active')) {
            $current_menu.removeClass('active');
        } else {
            var $currSwiper = $('.swiper-s-journals[data-cat="' + $current_menu.data('cat') + '"]');

            $('.s-journals-nav-single-top.active').removeClass('active');
            $current_menu.addClass('active');

            $('.swiper-s-journals.active').removeClass('active');
            loadArchiveJournalSwiper($currSwiper);
            $currSwiper.addClass('active');
        }
    });

    setupArchiveJournalSwipers();
}

function setupArchiveJournalSwipers() {
    let $initialSw = $('.swiper-s-journals:nth-child(1)');
    loadArchiveJournalSwiper($initialSw);
    $initialSw.addClass('active');
}

function loadArchiveJournalSwiper($container) {
    const $element = $container;
    if ($element.hasClass('swiper-container-initialized')) {
        return null;
    }

    const el = $element[0];

    const swiper = new Swiper(el,
        {
            lazy: {
                //  tell swiper to load images before they appear
                loadPrevNext: true,
                // amount of images to load
                loadPrevNextAmount: 1,
            },

            slidesPerView: 1.4,
            spaceBetween: 10,
            loop: false,
            centeredSlides: false,
            // loopAdditionalSlides: 12,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,

            // freeMode: true, // Enable free mode for scrolling and dragging
            touchReleaseOnEdges: true, // Allow scrolling beyond the edges
            mousewheel: {
                forceToAxis: true, // Enable horizontal mousewheel scrolling
            },

            breakpoints: {
                768: {
                    slidesPerView: 5.4,
                    centeredSlides: false,
                    spaceBetween: 20,
                    slidesOffsetBefore: 20,
                    slidesOffsetAfter: 20,
                },

            },

            on: {
                init() {

                },
                progress() {

                },
                slideChange() {

                },
                paginationUpdate(swiper, paginationEl) {
                    // const totalSlides = $( '.swiper-slide:not(.swiper-slide-duplicate)' ).length;
                    // $( '.swiper-pagination-total' ).html( totalSlides );
                },
            },

        });
    swiper.on('init', function () {
    });
    swiper.init();

}

/*------------------------------------*\
Single Journal
\*------------------------------------*/
function setupJournalPage() {
    setupJournalSecondaryMenu();
    setupVideoAutoplay();
}

function setupVideoAutoplay() {
    var options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // When half of the video is visible
    };

    var observer = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                $(entry.target).find('video')[0].play();
            } else {
                $(entry.target).find('video')[0].pause();
            }
        });
    }, options);

    $('.p-s-journal-content-single[data-type="video"]').each(function () {
        observer.observe(this);
    });
}

function setupMinHeightMobile() {
    var p_top = $('.p-text-nav').position().top;
    var p_height = $('.p-text-nav').outerHeight();
    var min_height = window.outerHeight - (p_height + p_top);
    $('.p-text-right-col').css('min-height', min_height);
}
function setupJournalSecondaryMenu() {

    $(".p-text-nav-in li span").on('click', function () {
        var target = $(this).data('target');
        var $section = $('#' + target);

        $(this).parent().siblings().find("span").removeClass('active');
        $(this).addClass('active');
        // $section.siblings().removeClass('active');
        // $section.addClass('active');
        $section.siblings().fadeOut(300, function () {
            $section.fadeIn(300);
        });

    });
}