/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/

$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    if (isFAQ()) {
        setupFAQ();
    } else {
        //e
    }
});

/*------------------------------------*\
HOME
\*------------------------------------*/
function setupFAQ() {
    // console.log(
    //     '<!-- ----------------------------------------------------- -->\n' +
    //     '<!-- FAQ -->\n' +
    //     '<!-- ----------------------------------------------------- -->');
    setupFAQScrollTrigger();
    setupFAQSecondaryMenuScrollTo();

    checkURLHashFAQ();

    if (isMobile() || isSmallScreen()) {
        addPaddingToSections();
    }else{
    }
}

function setupFAQScrollTrigger(){
    var offset = "top 80%";

    $('section').each(function(){
        ScrollTrigger.create({
            trigger: this,
            start: offset,
            end: () => '+='+ $(this).outerHeight(),
            scrub: true,
            markers: false,
            id:'sec_'+$(this).attr("id"),
            toggleClass: {
                targets: '.p-text-nav-in li span[data-target='+$(this).attr("id")+']',
                className: "active"
            },
            onEnter: self=>{
                var $current = $(self.trigger);
                var current_id = $current.attr('id');

                $(this).addClass('active');
            },
            onLeaveBack: self=>{
                $(this).removeClass('active');
            },
            onLeave: self=>{
                $(this).removeClass('active');

            },
            onEnterBack: self => {
                var $current = $(self.trigger);
                var current_id = $current.attr('id');
                var $current_nav = $('nav li[data-target='+current_id+']');

                $(this).addClass('active');
                updateMainMenuMagnification($current_nav);
            }
        });
    });

}

function setupFAQSecondaryMenuScrollTo(){

    $(".p-text-nav-in li span").on('click',function(){
        var target = $(this).data('target');

        if(target === "no-scroll") return;

        var speed = 3000;
        var duration =  ( Math.abs($('section.active').offset().top - $('#'+target).offset().top) / speed);

        var nav_offset = 0;

        if (isMobile() || isSmallScreen()) {
            nav_offset = $('.p-text-nav').outerHeight();
        }

        gsap.to(
            window,
            {
                duration: duration,
                scrollTo:{
                    y:'#'+target,
                    offsetY:nav_offset,
                    ease:  Sine.easeInOut
                }
            }
        );
    });

}

function checkURLHashFAQ(){
    if (location.href.indexOf("#") != -1) {
        var url_hash = location.href.substr(location.href.indexOf("#"));
        if($(url_hash).length === 1){
            $(window).scrollTop(0);
            var nav_offset = 0;

            if (isMobile() || isSmallScreen()) {
                nav_offset = $('.p-faq-nav').outerHeight();
            }

            var speed = 3000;
            var duration =  ( $(url_hash).offset().top / speed);

            gsap.to(
                window,
                {
                    duration: duration,
                    scrollTo:{
                        y:url_hash,
                        offsetY:nav_offset,
                        ease:  Sine.easeInOut
                    }
                }
            );
        }
    }
}

function addPaddingToSections(){
    $('section').css('padding-bottom', $('.p-faq-nav').outerHeight());
}
