/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/
$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    setupStockistsPage();
});

/*------------------------------------*\
STOCKISTS
\*------------------------------------*/
function setupStockistsPage() {

    setupStockistsSecondaryMenu();

}
function setupMinHeightMobile() {
    var p_top = $('.p-text-nav').position().top;
    var p_height = $('.p-text-nav').outerHeight();
    var min_height = window.outerHeight - (p_height + p_top);
    $('.p-text-right-col').css('min-height', min_height);
}
function setupStockistsSecondaryMenu() {

    $(".s-stockists-nav-in li span").on('click', function () {
        var target = $(this).data('target');
        var $section = $('#' + target);

        $(this).parent().siblings().find("span").removeClass('active');
        $(this).addClass('active');
        // $section.siblings().removeClass('active');
        // $section.addClass('active');
        $section.siblings().fadeOut(300, function () {
            $section.fadeIn(300);
        });

    });
}