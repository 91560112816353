/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/

// eslint-disable-next-line no-unused-vars
var lazyLoad;
var enableDebug = false;
var $main_menu;


$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    console.log(
        '<!-- ------------------------------------------------------------ -->\n' +
        '<!-- Code by David Penuela, http://davidpenuela.com (2021 — 2024) -->\n' +
        '<!-- ------------------------------------------------------------ -->');


    setupCommon();


});

/*------------------------------------*\
GENERAL
\*------------------------------------*/
function setupCommon() {
    $main_menu = $('nav li');

    if (isMobile() || isSmallScreen()) {
        setupMenuToggle_m();
    } else {
        //TODO
        setupMainMenuMaginificationHover_d();
    }
    fadeInElement();
    setupLazyLoadImages();


    if(enableDebug){
        setupDebug();
    }

}
/*------------------------------------*\
Navigation – main
\*------------------------------------*/
function setupMainMenuMaginificationHover_d(){
    $(".ul-main-menu a").on("mouseover", function(){
        updateMainMenuMagnification($(this).parent());
    }).on("mouseout",function(){
        var $c_active = $('nav li.active');
        updateMainMenuMagnification($c_active);
    });
}

function setupMenuToggle_m(){
    var $nav = $('nav');
    var $nav_toggle = $('.nav-toggle-mobile');
    $nav_toggle.on('click',function(){
        $nav.addClass('active');
    });
    $nav.on('click',function(e){
        if($(e.target).is('nav')){
            $nav.removeClass('active');
        }
    });
}


function setupMainMenuMaginification_m(){
    $(".ul-main-menu a").on("click", function(e){
        const target = $(e.target).parent().data('target');
        if(target === "no-scroll"){

        }else{
            e.preventDefault();
            e.stopPropagation();
            setStatusInBody($(this).parent().data('target'));
            updateMainMenuMagnification($(this).parent());

        }
    });

}

function clearMenuMagnification (){
    $main_menu.removeClass('bigger medium smaller');
}

function updateMainMenuMagnification($element){
    $main_menu.removeClass('bigger medium smaller');
    $element.closest('li').addClass('bigger');
    $element.next('li').addClass('medium');
    $element.next('li').next('li').addClass('smaller');
    $element.prev('li').addClass('medium');
    $element.prev('li').prev('li').addClass('smaller');
}


function setupLazyLoadImages() {
    lazyLoad = new LazyLoad({
        elements_selector: ".lazy",
        threshold: 10,
        load_delay: 500,
    });
}



/*------------------------------------*\
jQuery Events
\*------------------------------------*/
$(window).on('scroll', function () {

});




/*------------------------------------*\
Extra methods
\*------------------------------------*/

function setStatusInBody(id_hash){
    $('body').attr('data-active-section',id_hash);
}

function setupHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function fadeInElement(el = ".fadeInElement") {
    $(el).each(function (i) {
        $(this).delay((i + 1) * 150).fadeTo(300, 1);
    });
}


function isSmallScreen() {
    if ($(window).width() > 768) {
        return false;
    }
    return true;

}

function isMobile() {
    // eslint-disable-next-line no-useless-escape
    const newLocal = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || newLocal.test(navigator.userAgent.substr(0, 4))) {
        return true;
    }
    return false;

}


// Extra methods
function isHome() {
    if ($("body").hasClass("home")) {
        return true;
    }
    return false;
}

function isFAQ() {
    if ($("body").hasClass("page-template-page-faq")) {
        return true;
    }
    return false;
}

function isCartPage() {
    if ($("body").hasClass("woocommerce-cart")) {
        return true;
    }
    return false;
}

function isCheckoutPage() {
    if ($("body").hasClass("woocommerce-checkout")) {
        return true;
    }
    return false;
}

function isStockistsPage() {
    if ($("body").hasClass("page-template-page-stockists")) {
        return true;
    }
    return false;
}

function isSingleJournal() {
    if ($("body").hasClass("single-journal")) {
        return true;
    }
    return false;
}

function isArchiveJournal() {
    if ($("body").hasClass("post-type-archive-journal")) {
        return true;
    }
    return false;
}

function setupDebug(){
    let showing_debug_resolution = false;

    let $debug_resolution_w = $('#debug-resolution-w');
    let $debug_resolution_h = $('#debug-resolution-h');

    $debug_resolution_w.html($(window).outerWidth());
    $debug_resolution_h.html($(window).outerHeight());

    $(document).on('keypress', function (event) {
        if (event.key === 'g' || event.key === 'G') {
            $('.grid-columns').toggleClass('active');
        }
        if (event.key === 'r' || event.key === 'R') {
            $('.debug-resolutions').toggleClass('active');
            showing_debug_resolution = !showing_debug_resolution;

        }
    });
    $(window).on('resize', function () {
        if(showing_debug_resolution){
            $debug_resolution_w.html($(window).outerWidth());
            $debug_resolution_h.html($(window).outerHeight());
        }
    });

}

