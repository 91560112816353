/* ------------------------------------*\
	VARIABLE DECLARATION
\*------------------------------------*/

var $nav;

var layouts = [
	{
		//Orange
		dataImage: 'https://i.imgur.com/vrFwKza.jpeg',
		dataMobile: 'https://i.imgur.com/6twjPyc.jpg',
		textColor: '#751545',
		bodyBg: '#ffad5a'
	},
	{
		//Blue
		dataImage: 'https://i.imgur.com/bfBAijq.jpeg',
		dataMobile: 'https://i.imgur.com/BRLoNrr.jpg',
		textColor: '#F0EA9B',
		bodyBg: '#8BB8EE'
	},
	{
		//Green
		dataImage: 'https://i.imgur.com/zHzETo5.jpeg',
		dataMobile: 'https://i.imgur.com/wpt9R2s.jpg',
		textColor: '#DF6A7C',
		bodyBg: '#CFC481'
	}
];

$(window).on('load', () => {
	/*------------------------------------*\
		CALL TO FUNCTIONS
	\*------------------------------------*/
	if (isHome()) {
		setupHome();
	} else {
		//e
	}
});

/*------------------------------------*\
HOME
\*------------------------------------*/
function setupHome() {
	// console.log(
	//     '<!-- ----------------------------------------------------- -->\n' +
	//     '<!-- Home -->\n' +
	//     '<!-- ----------------------------------------------------- -->');
	$nav = $('nav');
	setupLayout(); //TODO: remove this method beacause we just use one background/color


	if (isMobile() || isSmallScreen()) {
		checkURLHashHome_m();
	} else {
		checkURLHashHome_d();
	}
	setupHeight();


	Marquee3k.init({
		selector: 'header_marquee', // define a custom classname
	});


	//specific for mobile
	if (isMobile() || isSmallScreen()) {
		setupMainMenuHomeScrollTo_m();
		setupMainMenuMaginification_m();
	} else {
		setupMainMenuHomeScrollTo_d();
		setupHomeScrollTrigger();
	}


	setTimeout(function () {
		setupAjaxAddToCart();
		setupSubNavigation();
		setupSocialSection();
		extraLinksScrollTo();
		// setupProductVariations();
	}, 1200);
}


function setupLayout() {
	var randomNumber = Math.floor(Math.random() * (2 - 0 + 1) + 0);
	var randomNumber = 0;
	$(".container-liquid").attr('data-image', layouts[randomNumber].dataImage).attr('data-mobile', layouts[randomNumber].dataMobile);
	document.documentElement.style.setProperty("--text-color", layouts[randomNumber].textColor);
	document.documentElement.style.setProperty("--body-bg", layouts[randomNumber].bodyBg);
}


function checkURLHashHome_d() {
	//we load directly the section, no animation
	//leave empty!
	setupLandingImageInteraction();
}

function checkURLHashHome_m() {
	if (location.href.indexOf("#") != -1) {
		var url_hash = location.href.substr(location.href.indexOf("#"));
		var $element_hash = $('li[data-target="' + url_hash.substr(1) + '"]');
		if ($(url_hash).length === 1) {
			$(url_hash).addClass('active');
			updateMainMenuMagnification($element_hash);
			setStatusInBody($element_hash.data('target'));
		}
	} else {
		setupLandingImageInteraction();
	}
}

/*------------------------------------*\
Navigation – subnav
\*------------------------------------*/

function setupSubNavigation() {
	setupInitialActiveSubnavigation();
	setupClickTransitionSubnavigation();
}

function setupInitialActiveSubnavigation() {
	$('.subnavigation li:first-child()').fadeIn().addClass('active');
	$('.section-col-content:first-child()').fadeIn().addClass('active');
	var $swipers = $('.section-col-content.active .section-col-content-swiper');
	$swipers.each(function () {
		var swiper = setupSwiper($(this), false);
		swiper.on('init', function () {

		});
		swiper.init();
		swiper.on('lazyImageReady', function () {
			swiper.update();
		});
	});
}

function setupClickTransitionSubnavigation() {
	$('.subnavigation span').on('click', function () {
		var menu_this = this;
		$(menu_this).parent().siblings().removeClass('active');
		$(menu_this).parent().addClass('active');

		var menu_index = $(menu_this).parent().index();

		//parent1 = li, parent2 = subnavigation, parent3= section-col-content, parent4: section-inside
		// var $parent = $(menu_this).parent().parent().parent().parent();
		var $content_container = $(menu_this).closest('.section-inside');

		var $col3_active = $content_container.find('.section-col3 .section-col-content.active');
		var $col3 = $content_container.find('.section-col3 .section-col-content:eq(' + menu_index + ')');

		var $col2_active = $content_container.find('.section-col2 .section-col-content.active');
		var $col2 = $content_container.find('.section-col2 .section-col-content:eq(' + menu_index + ')');


		$col2_active.fadeOut(300);
		setTimeout(function () {
			$col2.addClass('active').stop().fadeIn();
		}, 300);

		$col3_active.fadeOut(300);
		setTimeout(function () {
			$col3.addClass('active').stop().fadeIn();
			var $swiper_container = $col3.find('.section-col-content-swiper');

			if ($swiper_container.length > 0) {
				var swiper = setupSwiper($swiper_container, false);
				if (swiper !== null) {
					swiper.on('init', function () {
					});
					swiper.init();
				}
			}
		}, 300);
	});
}


/*------------------------------------*\
Sections — Social
\*------------------------------------*/
function setupSocialSection() {
	var swiper = setupSwiperSocial($('.section-social-gallery-inside'));
	swiper.on('init', function () {

	});
	swiper.init();

}

function setupSwiperSocial($el) {
	const $element = $el.find('.swiper-container');
	if ($element.hasClass('swiper-container-initialized')) {
		return;
	}
	let $next_button = $element.find('.swiper-button-next');
	let $prev_button = $element.find('.swiper-button-prev');

	if ($next_button.length < 1) {
		$next_button = $element.find('.swiper-button-next');
	}
	if ($prev_button.length < 1) {
		$prev_button = $element.find('.swiper-button-prev');
	}
	const el = $element[0];

	const swiper = new Swiper(el,
		{
			// in order to trigger the on('init') method i need to have init: false,
			init: false,
			// Disable preloading of all images
			preloadImages: false,
			// Enable lazy loading
			lazy: {
				//  tell swiper to load images before they appear
				//  loadPrevNext: true,
				// amount of images to load
				loadPrevNextAmount: 1,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			autoplay: {
				delay: 2000,
				pauseOnMouseEnter: true
			},

			slidesPerView: 1.3,
			spaceBetween: 20,
			loop: true,
			centeredSlides: false,
			loopedSlides: 12,
			// loopAdditionalSlides: 12,
			slidesOffsetBefore: 10,

			breakpoints: {
				768: {
					slidesPerView: 4.5,
					centeredSlides: false,
					spaceBetween: 20,
					slidesOffsetBefore: 20,

				},

			},

			on: {
				init() {

				},
				progress() {

				},
				slideChange() {

				},
				paginationUpdate(swiper, paginationEl) {
					// const totalSlides = $( '.swiper-slide:not(.swiper-slide-duplicate)' ).length;
					// $( '.swiper-pagination-total' ).html( totalSlides );
				},
			},

		});

	return swiper;
}

/*------------------------------------*\
Sections — General Swiper
\*------------------------------------*/
function setupSwiper($el, debug = false) {
	const $element = $el.find('.swiper-container');
	if ($element.hasClass('swiper-container-initialized')) {
		return null;
	}
	// $element.addClass('up-and-running-'+$element.data('swiper-id'));
	let $next_button = $element.find('.swiper-button-next');
	let $prev_button = $element.find('.swiper-button-prev');

	if ($next_button.length < 1) {
		$next_button = $('.swiper-button-next');
	}
	if ($prev_button.length < 1) {
		$prev_button = $('.swiper-button-prev');
	}
	const $pagination = $element.find('.swiper-pagination');
	const el = $element[0];


	var swiper_settings = {};


	swiper_settings = {
		init: false,
		lazy: true,

		navigation: {
			nextEl: $next_button[0],
			prevEl: $prev_button[0],
		},

		pagination: {
			el: $pagination[0],
			clickable: true,
		},

		slidesPerView: 1,
		spaceBetween: 20,
		loop: true,
		centerSlides: true,

		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		on: {
			slideChange: function () {
				var previousSlide = this.slides[this.previousIndex];
				var currentSlide = this.slides[this.activeIndex];

				// Pause video in the previous slide
				var previousVideo = previousSlide.querySelector('video');
				if (previousVideo) {
					previousVideo.pause();
				}

				// Play video in the current slide
				var currentVideo = currentSlide.querySelector('video');
				if (currentVideo) {
					currentVideo.play();
				}
			}
		}
	};

	// eslint-disable-next-line no-var
	var swiper = new Swiper(el, swiper_settings);
	if (debug) {
		console.log(swiper_settings);
		console.log('--- Call to setupSwiper() from 10_home.js ---');
		console.log('Param $element:');
		console.log($element);
		console.log(el);
		console.log('Variable $next_button:');
		console.log($next_button);
		console.log('Variable $prev_button:');
		console.log($prev_button);
		console.log('Variable pagination:');
		console.log($pagination);
		console.log('Variable swipper:');
		console.log(swiper);

		console.log('--- Call to setupSwiper() from 10_home.js ---');
	}
	return swiper;
}

/*------------------------------------*\
Gsap
\*------------------------------------*/

function extraLinksScrollTo() {
	var $links = $('.extra-scrollTo');
	$links.on('click', function (e) {
		var target = $(this).data('target');

		e.preventDefault();
		// e.stopPropagation();
		var target_index = $(target).index();
		var c_active_index = $('nav li.active').index();
		var speed = 3000;
		var duration = ((Math.abs(c_active_index - target_index) * $(window).outerHeight()) / speed);

		scrollToSection($(window).outerHeight() * target_index, duration);
	});
}

function setupMainMenuHomeScrollTo_d() {
	$("nav a").on('click', function (e) {
		var target = $(this).parent().data('target');

		if (target === "no-scroll") return;

		e.preventDefault();
		// e.stopPropagation();
		var target_index = $(this).parent().index();
		var c_active_index = $('nav li.active').index();
		var speed = 3000;
		var duration = ((Math.abs(c_active_index - target_index) * $(window).outerHeight()) / speed);
		scrollToSection($(window).outerHeight() * target_index, duration);

	});
}

function scrollToSection(target, duration) {
	gsap.to(
		window,
		{
			duration: duration,
			scrollTo: {
				y: target,
				offsetY: 0,
				ease: Sine.easeInOut
			}
		}
	);
}

function setupHomeScrollTrigger() {
	var offset = "top 80%";

	$('section').each(function () {
		ScrollTrigger.create({
			trigger: this,
			start: offset,
			end: () => '+=' + $(this).outerHeight(),
			scrub: true,
			markers: false,
			id: 'sec_' + $(this).attr("id"),
			toggleClass: {
				targets: 'nav li[data-target=' + $(this).attr("id") + ']',
				className: "active"
			},
			onEnter: self => {
				var $current = $(self.trigger);
				var current_id = $current.attr('id');
				var $current_nav = $('nav li[data-target=' + current_id + ']');

				$(this).addClass('active');
				if (current_id == 'landing' || current_id == 'footer') {
					clearMenuMagnification();
				} else {
					updateMainMenuMagnification($current_nav);
				}
			},
			onLeaveBack: self => {
				$(this).removeClass('active');
			},
			onLeave: self => {
				$(this).removeClass('active');

			},
			onEnterBack: self => {
				var $current = $(self.trigger);
				var current_id = $current.attr('id');
				var $current_nav = $('nav li[data-target=' + current_id + ']');

				$(this).addClass('active');
				if (current_id == 'landing' || current_id == 'footer') {
					clearMenuMagnification();
				} else {
					updateMainMenuMagnification($current_nav);
				}
			}
		});
	});

}

function setupMainMenuHomeScrollTo_m() {

	$("nav a").on('click', function (e) {
		var target = $(this).parent().data('target');
		if (target === "no-scroll") {
		} else {
			e.preventDefault();
			e.stopPropagation();
			$('section').removeClass('active');
			$('#' + target).addClass('active');
		}
		$nav.removeClass('active');

	});

}

/*------------------------------------*\
PRODUCT — ADD TO CART
\*------------------------------------*/

function setupAjaxAddToCart() {
	var $cta_add_to_cart = $('.button_add_to_cart');
	if ($cta_add_to_cart.length > 0) {
		$cta_add_to_cart.on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();
			var product_id = $(this).attr('data-value');
			var var_id = $('form').find('input[name="variation_id"]').val();
			var quantity = 1;
			if (quantity > 0) {
				jQuery.ajax({
					url: woocommerce_params.ajax_url,
					type: 'POST',
					data: 'action=woocommerce_ajax_add_to_cart&product_id=' + product_id + '&quantity=' + quantity + '&variation_id=' + var_id + '&mode=add_item',
					success: function (data) {
						// console.log("SUCCESS!");
						// console.log(data);
						$(document.body).trigger("update_checkout");
						// update counter
						var $counter = $('#mini-cart-count');
						var currentQuantity = parseInt($counter.html(), 10);
						var newQuantity = currentQuantity + 1;
						$counter.text(newQuantity);
						$('.section-col-content-product-added-feedback').fadeIn().delay(3000).fadeOut();

					},
					error: function (data) {
						// console.log('error');
						// console.log(data);
					}
				});

				return false;

			}

		});
	}

}

/*------------------------------------*\
Liquifi FUNCTIONS
\*------------------------------------*/

function setupLandingImageInteraction() {
	//https://observablehq.com/@radames/hello-ogl-minimal-webgl-library
	const vertex = `
      attribute vec2 uv;
      attribute vec2 position;
      varying vec2 vUv;
      void main() {
          vUv = uv;
          gl_Position = vec4(position, 0, 1);
      }
  `;
	const fragment = `
      precision highp float;
      precision highp int;
      uniform sampler2D tWater;
      uniform sampler2D tFlow;
      uniform float uTime;
      varying vec2 vUv;
      uniform vec4 res;

      void main() {

          // R and G values are velocity in the x and y direction
          // B value is the velocity length
          vec3 flow = texture2D(tFlow, vUv).rgb;

          vec2 uv = .5 * gl_FragCoord.xy / res.xy ;
          vec2 myUV = (uv - vec2(0.5))*res.zw + vec2(0.5);
          myUV -= flow.xy * (0.15 * 0.7);

          vec3 tex = texture2D(tWater, myUV).rgb;

          gl_FragColor = vec4(tex.r, tex.g, tex.b, 1.0);
      }
  `;
	var imgSize = [3600, 2400];

	const createCanvas = (slide) => {
		if (isMobile() || isSmallScreen()) {
			imgSize = [1624, 750];
		}
		const renderer = new ogl.Renderer({ dpr: 2 });
		const gl = renderer.gl;
		slide.appendChild(gl.canvas);

		// Variable inputs to control flowmap
		let aspect = 1;
		const mouse = new ogl.Vec2(-1);
		const velocity = new ogl.Vec2();

		var w_canvas = window.innerWidth;
		var h = window.innerHeight;
		function resize() {
			let a1, a2;
			var imageAspect = imgSize[1] / imgSize[0];
			if (window.innerHeight / window.innerWidth) {
				a1 = 1;
				a2 = window.innerHeight / window.innerWidth / imageAspect;
			}

			if (window.innerWidth < 576) {
				a1 = window.innerWidth / window.innerHeight / imageAspect;
				a2 = 1;

			}
			mesh.program.uniforms.res.value = new ogl.Vec4(
				window.innerWidth,
				window.innerHeight,
				a1,
				a2
			);

			renderer.setSize(window.innerWidth, window.innerHeight);
			aspect = window.innerWidth / window.innerHeight;
		}
		const flowmap = new ogl.Flowmap(gl, { falloff: 0.3, dissipation: 0.99 });
		// Triangle that includes -1 to 1 range for 'position', and 0 to 1 range for 'uv'.
		const geometry = new ogl.Geometry(gl, {
			position: {
				size: 2,
				data: new Float32Array([-1, -1, 3, -1, -1, 3])
			},
			uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) }
		});
		const texture = new ogl.Texture(gl, {
			minFilter: gl.LINEAR,
			magFilter: gl.LINEAR,
			premultiplyAlpha: true,
		});
		const img = new Image();
		img.onload = () => (texture.image = img);
		img.crossOrigin = "Anonymous";

		if (window.innerWidth < 576) {
			img.src = slide.getAttribute('data-mobile');
		} else {
			img.src = slide.getAttribute('data-image');
		}


		let a1, a2;
		var imageAspect = imgSize[1] / imgSize[0];
		if (window.innerWidth < 576) {
			a1 = window.innerWidth / window.innerHeight / imageAspect;
			a2 = 1;
		} else {
			a1 = (window.innerWidth / window.innerHeight) * imageAspect;
			a2 = 1;
		}

		const program = new ogl.Program(gl, {
			vertex,
			fragment,
			uniforms: {
				uTime: { value: 0 },
				tWater: { value: texture },
				res: {
					value: new ogl.Vec4(window.innerWidth, window.innerHeight, a1, a2)
				},
				img: { value: new ogl.Vec2(imgSize[0], imgSize[1]) },
				// Note that the uniform is applied without using an object and value property
				// This is because the class alternates this texture between two render targets
				// and updates the value property after each render.
				tFlow: flowmap.uniform
			}
		});
		const mesh = new ogl.Mesh(gl, { geometry, program });

		window.addEventListener("resize", resize, false);
		resize();

		// Create handlers to get mouse position and velocity
		const isTouchCapable = "ontouchstart" in window;
		if (isTouchCapable) {
			window.addEventListener("touchstart", updateMouse, false);
			window.addEventListener("touchmove", updateMouse, { passive: false });
		} else {
			window.addEventListener("mousemove", updateMouse, false);
		}
		let lastTime;
		const lastMouse = new ogl.Vec2();
		function updateMouse(e) {
			e.preventDefault();
			if (e.changedTouches && e.changedTouches.length) {
				e.x = e.changedTouches[0].pageX;
				e.y = e.changedTouches[0].pageY;
			}
			if (e.x === undefined) {
				e.x = e.pageX;
				e.y = e.pageY;
			}
			// Get mouse value in 0 to 1 range, with y flipped
			mouse.set(e.x / gl.renderer.width, 1.0 - e.y / gl.renderer.height);
			// Calculate velocity
			if (!lastTime) {
				// First frame
				lastTime = performance.now();
				lastMouse.set(e.x, e.y);
			}

			const deltaX = e.x - lastMouse.x;
			const deltaY = e.y - lastMouse.y;

			lastMouse.set(e.x, e.y);

			let time = performance.now();

			// Avoid dividing by 0
			let delta = Math.max(10.4, time - lastTime);
			lastTime = time;
			velocity.x = deltaX / delta;
			velocity.y = deltaY / delta;
			// Flag update to prevent hanging velocity values when not moving
			velocity.needsUpdate = true;
		}
		update();
		function update() {
			// Reset velocity when mouse not moving
			if (!velocity.needsUpdate) {
				mouse.set(-1);
				velocity.set(0);
			}
			velocity.needsUpdate = false;
			// Update flowmap inputs
			flowmap.aspect = aspect;
			flowmap.mouse.copy(mouse);
			// Ease velocity input, slower when fading out
			flowmap.velocity.lerp(velocity, velocity.len ? 0.15 : 0.1);
			flowmap.update();
			renderer.render({ scene: mesh });
			requestAnimationFrame(update);
		}

		$("nav a").on('click', function () {
			const isTouchCapable = "ontouchstart" in window;
			if (isTouchCapable) {
				window.removeEventListener("touchstart", updateMouse, false);
				window.removeEventListener("touchmove", updateMouse, { passive: false });
			}
		});
	};
	createCanvas($('.container-liquid')[0]);

}
