/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/
$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    if (isHome()) {
        setupMCAjax();
        setTimeout(function () {
            setupPopupNewsletter();
        }, 3000);
    }
});


/*-------------------------------------*\
Mailchimp
\*------------------------------------*/
function callbackFunction(resp) {
    if (resp.result === 'success') {
    }
}
function setupMCAjax() {
    var $forms = $('.mc-embedded-subscribe-form');
    $forms.each(function (el) {
        $(this).ajaxChimp({
            callback: callbackFunction,
        });
    });
}

function setupPopupNewsletter() {
    const $cookie_box_container = $('.s-newsletter-popup');
    if (Cookies.get('ms_cookie') != 'hide') {
        const $cookie_close_button = $('#s-cookies_close');

        $cookie_box_container.addClass('active');

        $cookie_close_button.on('click', function () {
            $cookie_box_container.removeClass('active');
            Cookies.set('ms_cookie', 'hide', { expires: 7 });
        });
    }
}