/* ------------------------------------*\
    VARIABLE DECLARATION
\*------------------------------------*/

$(window).on('load', () => {
    /*------------------------------------*\
        CALL TO FUNCTIONS
    \*------------------------------------*/
    if (isCartPage()) {
        setupCartPage();
    } else {
        //e
    }
});

/*------------------------------------*\
HOME
\*------------------------------------*/
function setupCartPage() {
    // console.log(
    //     '<!-- ----------------------------------------------------- -->\n' +
    //     '<!-- CART -->\n' +
    //     '<!-- ----------------------------------------------------- -->');
    setupCartUpdateQantity();

    if (isMobile() || isSmallScreen()) {
    }else{
    }
}

function setupCartUpdateQantity(){
    $( 'div.woocommerce' ).on( 'change', 'input.qty', function() {
        $("[name='update_cart']").removeAttr( 'disabled' );
        $("[name='update_cart']").trigger("click");
    });
}
